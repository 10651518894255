
import {Component, Mixins, Prop, Ref, Watch} from "vue-property-decorator";
import FormWrapper from "@/components/FormWrapper.vue";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import EPosta from "@/components/inputs/EPosta.vue";
import VergiNumarasi from "@/components/inputs/VergiNumarasi.vue";
import AdresForm from "@/components/forms/AdresForm.vue";
import DilekceEditor from "@/components/inputs/DilekceEditor.vue";
import {DilekceEntity} from "@/entity/DilekceEntity";
import DilekceOlusturForm from "@/components/forms/DilekceOlusturForm.vue";
import TakipDilekceOlusturForm from "@/components/forms/TakipDilekceOlusturForm.vue";
import {Udf} from "@/classes/HtmlToUdf";
//@ts-ignore
import html2pdf from "../../../node_modules/html2pdf.js/dist/html2pdf";
import JSZip, {forEach} from 'jszip';
import * as FileSaver from 'file-saver';

@Component({
  components: {
    TakipDilekceOlusturForm,
    DilekceOlusturForm,
    DilekceEditor,
    FormWrapper,
    EPosta,
    VergiNumarasi,
    AdresForm
  }
})
export default class DilekceForm extends Mixins(ObjectInputMixin) {
  @Prop() download!: boolean | string;
  @Prop() downloadFromTakip!: boolean | string;
  @Prop() takipId!: number;
  @Ref('dilekceEditor') readonly dilekceEditor!: DilekceEditor;
  @Ref('form') readonly form!: FormWrapper;

  localValue: DilekceEntity = new DilekceEntity();
  manuelDilekceVerileri:any = {};
  secilenDilekceler: Array<DilekceEntity> = [];
  error = false;

  get isDownload() {
    return this.download || this.download === "";
  }

  get isDownloadFromTakip() {
    return this.downloadFromTakip || this.downloadFromTakip === "";
  }

  @Watch('localValue.sablon')
  isSablonChange() {
    this.error = false;
  }
  onClose(){
    this.$emit("onClose");

  }
  async save(isFarkliKaydet: boolean = false, indirmeTuru: string = '') {
    if (this.isDownload) {
      // OfisPro üzerinden manuel dilekçe oluşturma
      const tarihAlanlari = ['bugunun_tarihi', 'vade_tarihi', 'haciz_tarihi', 'takip_tarihi'];

      for (const tarih of tarihAlanlari) {
        this.manuelDilekceVerileri[tarih] = this.$helper.tarihStr(this.manuelDilekceVerileri[tarih]);
      }
      this.$http.post('/api/v1/dilekce/' + this.localValue.id + '/render', this.manuelDilekceVerileri).then((response) => {
        let responseData = [{isim: this.localValue.isim, data: response.data}];
        this.downloadNow(responseData, indirmeTuru);
        this.$emit('onSuccess');
      })
    } else if (this.isDownloadFromTakip) {
      // İcraPro takip üzerinden çoklu dilekçe oluşturma
      if (this.form.validateForm()) {
        let dilekcelerResponse = [];
        let response;
        for (const dilekce of this.secilenDilekceler) {
          response = await this.$http.post('/api/v1/takip/' + this.takipId + '/dilekce/' + dilekce.id + '/render');
          dilekcelerResponse.push({isim: dilekce.isim, data: response.data});
        }
        await this.downloadNow(dilekcelerResponse, indirmeTuru);
      }
    } else {
      this.dilekceEditor?.findAlanlar();
      if (this.localValue.id) {
        if (isFarkliKaydet) {
          if (this.localValue.isim == this.dilekceEditor.eskiDilekceIsim)
            this.localValue.isim = this.localValue.isim + '(' + new Date().toISOString() + ')';
          delete this.localValue.id;
          this.localValue.standart_mi = false;
          this.$http.post('/api/v1/dilekce/', this.localValue).then(() => {
            this.$emit('onSuccess');
          });
        } else {
          if (!this.localValue.sablon || this.localValue.sablon == "") this.error = true;
          else {
            this.$http.put('/api/v1/dilekce/' + this.localValue.id, this.localValue).then(() => {
              this.$emit('onSuccess');
            });
          }
        }
      } else {
        if (!this.localValue.sablon || this.localValue.sablon == "") this.error = true;
        else {
          this.$http.post('/api/v1/dilekce', this.localValue).then(() => {
            delete this.localValue.sablon;
            this.$emit('onSuccess');
          })
        }
      }
    }
  }

  async downloadNow(response: any, indirmeTuru: string) {
    let zip = new JSZip();
    if (indirmeTuru == 'udf') {
      response.forEach((dilekce:any) => {
        let udf = new Udf('',[]);
        let cleanHTML = udf.htmlCleaner(dilekce.data);
        let XMLTemplateTag = udf.XMLTemplateTagCreated(cleanHTML);
        let newReplaceXML = udf.prepareToDownload(XMLTemplateTag, 'deneme', true);
        if (newReplaceXML!==undefined){
          let file = new Blob([newReplaceXML], {type: 'application/xml'});
          zip.file(dilekce.isim + ".udf", file);
        }
      })
    } else if (indirmeTuru == 'word') {
      let header = "<html xmlns:o='urn:schemas-microsoft-com:office:office' " +
          "xmlns:w='urn:schemas-microsoft-com:office:word' " +
          "xmlns='http://www.w3.org/TR/REC-html40'>" +
          "<head><meta charset='utf-8'><title>Save File</title></head><body>";
      let footer = "</body></html>";
      response.forEach((dilekce:any) => {
        let sourceHTML = header + dilekce.data + footer;
        const file = new Blob([sourceHTML], {type: 'application/vnd.ms-word;charset=utf-8'});
        zip.file(dilekce.isim + ".doc", file);
      })
    } else if (indirmeTuru == 'pdf') {
      let options = {
        margin: 1,
        image: {type: 'jpeg', quality: 0.98},
        html2canvas: {scale: 2},
        jsPDF: {unit: 'in', format: 'letter', orientation: 'portrait'}
      };
      for (let index = 0; index < response.length; index++) {
        await html2pdf().set(options).from(response[index].data).toPdf().get('pdf').then((pdf:any) => {
          const totalPages = pdf.internal.getNumberOfPages()
          for (let i = 1; i <= totalPages; i++) {
            pdf.setPage(i)
            pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88),
                (pdf.internal.pageSize.getHeight() - 0.3))
          }
        }).outputPdf('blob').then((file:any) => {
          zip.file(response[index].isim + ".pdf", file);
        })
      }
    }
    zip.generateAsync({type: "blob"})
        .then(function (contents) {
          FileSaver.saveAs(contents, 'Dilekçeler');
        });
  }
}
